/*  
------------------------------------------------------------------------------------------------ 
    *theme - THEME STYLES ALL IN HERE
------------------------------------------------------------------------------------------------ 
*/


        /* IMPORT PARTIALS
        ------------------------------------------------------------------------- */

                @import '_scss/variables';




        /* COVER THEME VARS TO CSS-CLASSES
        ------------------------------------------------------------------------- */

                .bg-dark {
                    background-color: $bg-dark !important;
                }  

                .bg-gray {
                    background-color: $bg-gray !important;
                }

                .bg-black {
                    background-color: $bg-black !important;
                }

                .bg-light {
                    background-color: $bg-light !important;
                }

                .bg-white {
                    background-color: $bg-white !important;
                }

                .bg-footer {
                    background-color: $bg-footer !important;
                }

                .bg-search {
                    background-color: $bg-search !important;
                }

                .text-dark {
                    color: $text-dark !important;
                } 

                .text-gray {
                    color: $text-gray !important;
                } 

                .text-black {
                    color: $text-black !important;
                }

                .text-light {
                    color: $text-light !important;
                } 

                .text-white {
                    color: $text-white !important;
                } 

                .text-footer {
                    color: $text-footer !important;
                } 

                .text-search {
                    color: $text-search !important;
                } 

                .border-dark {
                    border-color: $border-dark !important;
                } 

                .border-gray {
                    border-color: $border-gray !important;
                } 

                .border-black {
                    border-color: $border-black !important;
                } 

                .border-light {
                    border-color: $border-light !important;
                } 

                .border-white {
                    border-color: $border-white !important;
                } 

                .border-footer {
                    border-color: $border-footer !important;
                } 

                .border-search {
                    border-color: $border-search !important;
                } 
                
                .client-bg {
                    background-color: $client-bg !important;
                }

                .client-bg-hover {
                    background-color: $client-bg-hover !important;
                }

                .client-svg {
                    color: $client-svg !important;
                }

                .client-text {
                    color: $client-text !important;
                }

                .form-text-invalid {
                    color: $form-text-invalid !important;
                }


                .nav-top-header-bg {
                    color: $nav-top-header-bg !important;
                }

                .nav-top-header-text {
                    color: $nav-top-header-text !important;
                }

                .nav-bg {
                    color: $nav-bg !important;
                }

                .nav-text {
                    color: $nav-text !important;
                }

                .nav-border {
                    color: $nav-border !important;
                }

                .nav-svg {
                    color: $nav-svg !important;
                }

                .nav-active-bg {
                    color: $nav-active-bg !important;
                }

                .nav-active-text {
                    color: $nav-active-text !important;
                }

                .nav-hover-bg {
                    color: $nav-hover-bg !important;
                }

                .nav-hover-text {
                    color: $nav-hover-text !important;
                }

                .nav-hover-svg-bg {
                    color: $nav-hover-svg-bg !important;
                }

                .nav-hover-svg-text {
                    color: $nav-hover-svg-text !important;
                }

                .nav-dropdown-bg {
                    color: $nav-dropdown-bg !important;
                }

                .nav-dropdown-text {
                    color: $nav-dropdown-text !important;
                }





        /* GENERAL COLOR SETTINGS
        ------------------------------------------------------------------------- */

                body {
                    background-color: $nav-top-header-bg;
                    color: $text-gray;
                }


                section, button, .modal-content {
                    a {
                        color: $client-svg;
            
                        &:hover {
                            color: $client-bg-hover !important;
                        }
                    }
                                
                    .big-link {
                        color: inherit;

                        &:hover {
                            color: #E6E6FD !important;
                        }
                    }
                }

                .iso-pic {
                    a {
                        color: $client-svg;
            
                        &:hover {
                            color: #ffffff !important;
                        }
                    }
                }

                
                a.dark-bg {
                    color: client-svg;
                    &:hover {
                        color: #ffffff !important;
                    }
                }

                a.light-bg {
                    color: client-svg;
                    &:hover {
                        color: #aea597 !important;
                    }
                }
                

                
                

                .modal-content {
                    border: 1px solid $bg-black !important;
                }

                
                .loader {
                    background-color: $bg-black;

                    .bar-container { 
                        background-color: $bg-black;  

                        .bar {
                            opacity: 1;
                            background-color: $nav-hover-bg;
                        }
                    }
                }


                .cookie-window {
                    background-color: $bg-black;
                    color: $text-gray;
                }


                #site-loader {
                    color: $nav-hover-bg;
                }

                .divider {
                    // border-color: #ffffff !important;
                    border-color: #dedad6 !important;
                }







        /* NAVIGATION
        ------------------------------------------------------------------------- */

                .lc-top-header {
                    background-color: $nav-top-header-bg;
                    color: $nav-top-header-text;
                }

                
                .lc-navigation {
                    background-color: $nav-bg;
                    color: $nav-text;
                }


                .lc-navbar {
                    background-color: $nav-bg;
                    color: $nav-text;
    
                    a {
                        color: $nav-text !important;

                        svg {
                            color: $nav-svg;
                        }

                        &:hover {
                            background-color: $nav-hover-bg;
                            color: $nav-hover-text !important;

                            svg {
                                background-color: $nav-hover-svg-bg;
                                color: $nav-hover-svg-text !important;
                            }
                        }

                        &.navbar-active {
                            background-color: $nav-active-bg !important;
                            color: $nav-active-text !important;
                            border: 1px dashed $nav-active-text;
                        }

                        &.nav-logo:hover {
                            background-color: transparent;
                        }

                    }


                    .lc-dropdown {
                        &:hover .lc-dropdown-btn {
                            background-color: $nav-hover-bg !important;
                            color: $nav-hover-text !important;
    
                            svg {
                                color: $nav-hover-svg-text !important;
                            }
                        }
                    }


                    .lc-dropdown-btn {
                        background-color: $nav-bg !important;
                        color: $nav-text !important;
    
                        svg {
                            color: $nav-svg !important;
                        }
                    }

                    

                    .lc-dropdown-content {
                        background-color: $nav-dropdown-bg;
                        color: $nav-dropdown-text;
    
                        a {
                            color: $nav-text !important;
    
                            &:hover {
                                background-color: $nav-hover-bg !important;
                                color: $nav-hover-text !important;
                            }
                        }
                    }
                }

                


                .lc-sidenav {
                    background-color: $nav-bg !important;
                    color: $nav-text !important;

                    a {
                        color: $nav-text !important;

                        svg {
                            color: $nav-svg;
                        }

                        &:hover {
                            background-color: $nav-hover-bg;
                            color: $nav-hover-text !important;

                            svg {
                                background-color: $nav-hover-svg-bg;
                                color: $nav-hover-svg-text !important;
                            }
                        }

                        &.sidenav-active {
                            background-color: $nav-active-bg !important;
                            color: $nav-active-text !important;
                            border: 1px dashed $nav-hover-text;

                            svg {
                                color: $nav-hover-text !important;
                            }
                        }
                    
                    }

                    .lc-sidenav-dropdown {
                        background-color: inherit;
                        color: inherit;
                    }


                    .lc-sidenav-dropdown-btn {
                        background-color: inherit;
                        color: inherit;
                    }

                    .lc-sidenav-dropdown {
                        &:hover .lc-sidenav-dropdown-btn {
                            background-color: $nav-hover-bg !important;
                            color: $nav-hover-text !important;

                            svg {
                                color: $nav-hover-svg-text !important;
                            }
                        }
                    }


                    .lc-sidenav-dropdown-content {
                        background-color: $nav-dropdown-bg;
                        color: $nav-dropdown-text;
    
                        a {
                            color: $nav-text !important;
    
                            &:hover {
                                background-color: rgb(184, 181, 181) !important;
                                color: $nav-bg !important;

                                svg {
                                    background-color: rgb(184, 181, 181) !important;
                                    color: $nav-svg !important;
                                }
                            }
                        }
                    }
                }





        /* BLOGPOST & BLOGPOST-SEARCH
        ------------------------------------------------------------------------- */

                .blogpost-overview {

                    a {
                        color: $text-dark !important;

                        &:hover {
                            color: $client-bg !important;
                        }
                    }
                }


                .blogpost-image-copyright, .blogpost-overview-image-copyright {
                    color: $text-dark;
                }


                .badge-blogpost {
                    background-color: $client-bg;
                    color: $text-white;
                }


                .blogpost-header, .blogpost-overview-header {
                    color: $client-bg;
                }
        

                #blogpost-overview-search-form input, 
                #blogpost-navigate-form-1 input, 
                #blogpost-navigate-form-2 input {
                    background-color: $bg-search;
                    color: $text-search;
                }


                #blogpost-overview-search-form button, 
                #blogpost-navigate-form-1 button, 
                #blogpost-navigate-form-2 button {
                    background-color: $client-svg;
                    color: $client-text;
                }


                #blogpost-overview-search-form .search-btn {
                    border-right: 1px solid $border-search;
                }


                #blogpost-overview-search-close-btn {
                    background-color: $client-svg;
                    color: $client-text;
                }






        /* ABOUT US - TEAM-WIDGET
        ------------------------------------------------------------------------- */

                .team-widget-header {
                    background-color: #394263;
                }


                .team-widget-content {
                    h3 {
                        color: #97adcf;
                    }
                    h4 {
                        color: #eeeeee;
                    }                    
                }


                .team-widget-image {
                    background-color: $bg-white;
                }





        /* ABOUT US - SERVICE-WIDGET
        ------------------------------------------------------------------------- */

                .service-widget-header {
                    background-color: transparent;
                }


                .service-widget-content {
                    h3 {
                        color: #394263;
                    }
                    h4 {
                        color: #394263;
                    }
                }


                .service-widget-image-round {
                    background-color: #394263;
                }


                .service-widget-image-round svg.svg-inline--fa {
                    color: $bg-white;
                }


                .service-widget-block {
                    background-color: $bg-light;

                    &.shadow{
                        box-shadow: 0 0.15rem 1rem #e9d2af!important;
                    }
                }


                .service-widget-block svg.svg-inline--fa {
                    color: $client-svg;
                }


                .service-widget-image-transparent {
                    background-color: transparent;
                }


                .service-widget-image-transparent svg.svg-inline--fa {
                    color: $client-svg;
                }





        /* ABOUT US - PORTFOLIO-WIDGET
        ------------------------------------------------------------------------- */

                .portfolio-widget {
                    a {
                        color: $text-dark !important;

                        &:active, &:focus, &:visited {
                            color: $text-dark !important;
                        }

                        &:hover {
                            color: $client-bg !important;
                        }
                    }
                }


                .portfolio-widget-header {
                    background-color: transparent;
                }


                .portfolio-widget-content h3 {
                    color: $text-dark;

                }


                .portfolio-widget-image {
                    background-color: transparent;

                    i {
                        color: $client-svg;
                    }
                }





        /* PRICING-WIDGET
        ------------------------------------------------------------------------- */


                .pricing-widget-header {
                    background-color: #394263;
                }


                .pricing-widget-content {
                    h1 {
                        color: #97adcf;
                    }
                    h3 {
                        color: #ffffff;
                    }
                }


                .pricing-widget-image {
                    background-color: $bg-white;
                }


                .pricing-detail-btn {
                    background-color: $client-bg;
                    color: $client-text;
                }




        /* FAQ-ACCORDION
        ------------------------------------------------------------------------- */

                .card.card-light-bg {
                    border-color: $border-black;
                }
                .card.card-dark-bg {
                        border-color: $border-light;
                }
                

                .card, .card-body, .card-header, .card-heading {
                    background-color: transparent;
                }


                .accordion-custom .card-heading:after {
                    color: $text-dark;
                    background-color: transparent;
                }


                .accordion-custom .card-heading.lawyer:after {
                    color: $text-dark;
                    background-color: transparent;
                }
                

                .card, .card-body, .card-header, .card-heading {
                    .lawyer {
                        a {
                            color: $text-gray !important;
    
                            &:active, &:focus, &:visited {
                                color: $text-gray !important;
                            }
    
                            &:hover {
                                color: $client-svg !important;
                            }
                        }
                    }
                }


    


        /* ISOTOPE CONTAINER - used for GALERY, REFERENCES & PROJECTS OVERVIEW
        /* INCL. FILTERING
        ------------------------------------------------------------------------- */


                // .iso-pic-label {
                //     background-color: $bg-dark;
                //     color: $text-dark;
                // }




        /* SWIPER STYLES
        ------------------------------------------------------------------------- */


                body.slider {
                    background-color: $bg-black;
                }


                .galery-loader {
                    background-color: $bg-black;

                    svg {
                        color: $client-bg;
                    }
                }


                .swiper-container {
                    color: $text-white;
                    background: transparent !important;
                }


                .arrow-left {
                    color: $text-light;
                    
                    svg {
                        color: $text-light !important;

                        &:hover {
                            color: $client-svg !important;
                        }
                    }
                }


                .arrow-right {
                    color: $bg-light;

                    svg {
                        color: $text-light;

                        &:hover {
                            color: $client-svg;
                        }
                    }
                }


                .swiper-pagination {
                    background-color: transparent;
                    color: $client-text;
                }

                .swiper-pagination-bullet {
                    background: $bg-gray !important;
                }

                .swiper-pagination-bullet-active {
                    background: $client-svg !important;
                }

                
                .close-btn {
                    color: $client-svg;

                    &:hover {
                        color: $bg-light;
                    }
                }



                

        /* CONTACT FORMULAR
        ------------------------------------------------------------------------- */


                #contact-form input[type=text], #subscribe-form input[type=text],
                #covid-form input[type=text], #covid-form input[type=select],
                #storno-form input[type=text], #storno-form input[type=select],
                #order-form input[type=text] {
                    background-color: $bg-white;
                    color: $text-gray;
                }


                #contact-form textarea, #covid-form textarea, #order-form textarea,
                #storno-form textarea {
                    background-color: $bg-white;
                    color: $text-gray;
                }


                #mail-message, 
                #subscribe-message {
                    color: $client-bg;

                    small {
                        color: $client-bg;
                    }
                }

                label {
                    small {
                        color: $client-bg;
                    }
                }


                .invalid-feedback, .is-invalid {
                    color: $form-text-invalid;
                }

                .form-check-input.is-invalid~.form-check-label, 
                .was-validated .form-check-input:invalid~.form-check-label {
                    color: $form-text-invalid;
                }

                .custom-control-label:before {
                    background-color: transparent;
                }

                .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
                    color: $text-black;
                    background-color: $bg-black;
                }

                .custom-radio .custom-control-input:checked~.custom-control-label::before {
                    color: $text-black;
                    background-color: $bg-black;
                }


                .submit-btn {
                    background-color: $bg-white;
                    color: $text-gray;
                }


        


        /* FOOTER
        ------------------------------------------------------------------------- */

                footer {
            
                    a {
                        color: $text-footer !important;

                        &:active, &:focus, &:visited {
                            color: $text-footer !important;
                        }

                        &:hover {
                            color: $client-bg !important;
                        }
                    }

                    // h4 {
                    //     border-bottom: 1px solid;
                    // }

                    i {
                        color: $client-svg;
                    }
                }