/*  
------------------------------------------------------------------------------------------------ 
    * UTILITIES - STYLING & SPACING DEFINITIONS OF ELEMENTS
    * Controls element´s opacity, paddings & margins using the following classes 
    
        .opacity-#  ( value 0 - 100 )
        .padding-#  ( value 0 - 100 )
        .margin-#   ( value 0 - 100 )
           
------------------------------------------------------------------------------------------------ 
*/


        
        /*  MIXIN FOR BREAKPOINTS
        ----------------------------------------------------------------------------- */

                @mixin responsive($device) {
                    @if $device == xs {
                        @media only screen and (max-width: 575.98px) { 
                            @content; 
                        }
                    }
                    @else if $device == md {
                        @media only screen and (max-width: 991.98px) { 
                            @content; 
                        }
                    }
                }


  

        /*  OPACITY
        ------------------------------------------------------------------------------- */

                .opacity-0 {
                    opacity: 0;
                }
                
                @for $i from 1 through 9 {
                    $opacity: ($i / 10);
                    .opacity-#{($i*10)} {
                        opacity: $opacity;
                    }
                }



        /*  SPACING - PADDING
        ----------------------------------------------------------------------------- */

                // padding all
                .p-all-0 {
                    padding: 0;
                }

                @for $i from 1 to 10 {
                    .p-all-#{($i*10)} {
                        padding: ($i * 10px) !important;
                        @include responsive(md) { padding: round((($i * 10px) / 2)) !important; }
                        @include responsive(xs) { padding: round((($i * 10px) / 3)) !important; }
                    }
                }


                // padding-top & padding-bottom
                .p-tb-0 {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                @for $i from 1 to 10 {
                    .p-tb-#{($i*10)} {
                        padding-top: ($i * 10px) !important;
                        padding-bottom: ($i * 10px) !important;
                        @include responsive(md) { padding-top: round((($i * 10px) / 2)) !important; padding-bottom: round((($i * 10px) / 2)) !important; }
                        @include responsive(xs) { padding-top: round((($i * 10px) / 3)) !important; padding-bottom: round((($i * 10px) / 3)) !important; }
                    }
                }



                // padding-left & padding-right
                .p-lr-0 {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                @for $i from 1 to 10 {
                    .p-lr-#{($i*10)} {
                        padding-left: ($i * 10px) !important;
                        padding-right: ($i * 10px) !important;
                        @include responsive(md) { padding-left: round((($i * 10px) / 2)) !important; padding-right: round((($i * 10px) / 2)) !important; }
                        @include responsive(xs) { padding-left: round((($i * 10px) / 3)) !important; padding-right: round((($i * 10px) / 3)) !important; }
                    }
                }





        /*  SPACING - MARGIN
        ----------------------------------------------------------------------------- */

                // margin all
                .m-all-0 {
                    padding: 0;
                }

                @for $i from 1 to 10 {
                    .m-all-#{($i*10)} {
                        padding: ($i * 10px) !important;
                        @include responsive(md) { padding: round((($i * 10px) / 2)) !important; }
                        @include responsive(xs) { padding: round((($i * 10px) / 3)) !important; }
                    }
                }


                // margin-top & margin-bottom
                .m-tb-0 {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                @for $i from 1 to 10 {
                    .m-tb-#{($i*10)} {
                        padding-top: ($i * 10px) !important;
                        padding-bottom: ($i * 10px) !important;
                        @include responsive(md) { padding-top: round((($i * 10px) / 2)) !important; padding-bottom: round((($i * 10px) / 2)) !important; }
                        @include responsive(xs) { padding-top: round((($i * 10px) / 3)) !important; padding-bottom: round((($i * 10px) / 3)) !important; }
                    }
                }


                // margin-left & margin-right
                .m-lr-0 {
                    padding-left: 0;
                    padding-right: 0;
                }

                @for $i from 1 to 10 {
                    .m-lr-#{($i*10)} {
                        padding-left: ($i * 10px) !important;
                        padding-right: ($i * 10px) !important;
                        @include responsive(md) { padding-left: round((($i * 10px) / 2)) !important; padding-right: round((($i * 10px) / 2)) !important; }
                        @include responsive(xs) { padding-left: round((($i * 10px) / 3)) !important; padding-right: round((($i * 10px) / 3)) !important; }
                    }
                }