/*  
------------------------------------------------------------------------------------------------ 
    * NAV - MENU NAVIGATION STYLES ALL IN HERE
------------------------------------------------------------------------------------------------ 
*/

        /* IMPORT PARTIALS
        ------------------------------------------------------------------------- */

            @import '_scss/variables';
            @import '_scss/mixins';



        
        /* NAVIGATION
        ------------------------------------------------------------------------- */

        .lc-top-header {
            max-height: 250px;
            margin-top: auto;
            margin-bottom: auto;
            text-align: center;
            z-index: 1099;

            img {
                height: 250px;
            }
        }

        
        .lc-navigation {
            height: auto;
            z-index: 1099;
            font-weight: 400;

            
            .lc-navlogo img {
                height: 90px;
                // display: none;
            }
        }


        .lc-navbar {
            overflow: hidden;
            font-weight: inherit;
            height: 90px;
            

            a {
                display: block;
                padding: 10px 10px;
                margin-left: 10px;
                text-decoration: none;
                font-size: 17px;

                &.last {
                    margin-right: 15px;
                }

            }

            .lc-toggle-icon {
                display: none;

                svg {
                    font-size: 26px;
                }
            }

            .lc-dropdown {
                overflow: hidden;

                .lc-dropdown-btn {
                    font-family: inherit;
                    font-weight: inherit;
                    font-size: 16px;
                    border: none;
                    outline: none;
                    padding: 10px 15px;
                    margin-left: 20px;
                    margin: 0;
                }

                &:hover .lc-dropdown-content {
                    display: block;
                }
            }

            .lc-dropdown-content {
                display: none;
                position: fixed;
                min-width: 200px;
                z-index: 1100;

                a {
                    float: none;
                    padding: 8px 12px;
                    margin-left: 0 !important;
                    text-decoration: none;
                    display: block;
                    text-align: left;

                    svg {
                        margin-right: 3px;
                    }
                }
            }
        }
        

        .lc-sticky {
            position: fixed;
            top: 0;
            width: 100%;
        }


        .first-content-margin {
            margin-top: 90px !important;
        }




        /* SIDE-NAVIGATION - OFF CANVAS FROM LEFT SIDE
        ------------------------------------------------------------------------- */


        .lc-sidenav {
            font-weight: 500;
            height: 100%;
            width: 0; 
            position: fixed; 
            z-index: 1100; 
            top: 0;
            left: 0;
            overflow-x: hidden; 
            padding-top: 69px;

            a {
                margin-bottom: 10px;
                padding: 8px 8px 8px 32px;
                text-decoration: none;
                font-size: 17px;
                display: block;
            
                &.lc-close-icon {
                    position: absolute;
                    top: 10px;
                    right: 15px;
                    padding: 12px !important;
                    font-size: 26px;
                }
            }

            .lc-sidenav-dropdown {
                overflow: hidden;

                .lc-sidenav-dropdown-btn {
                    font-family: inherit;
                    font-weight: inherit;
                    display: block;
                    font-size: 17px;
                    border: none;
                    outline: none;
                    margin-top: 10px;
                    padding: 8px 8px 8px 32px;
                    
                }
            }
        }

        
        .lc-sidenav-dropdown-content {
            display: none;

            a {
                margin-bottom: 10px;
                padding: 8px 8px 8px 30px !important;
                text-decoration: none;
                font-size: 17px;
                display: block;
            }
        }


        .dropdown-show {
            display: block;
        }






        /* RESPONSIVE STUFF
        ------------------------------------------------------------------------- */


        @media screen and (max-width: 767.98px) {
            .lc-navbar {

                a, .lc-dropdown  {
                    display: none;

                    &.lc-toggle-icon {
                        float: right;
                        display: block;
                    }
                }
            }

            // .first-content-margin {
            //     margin-top: 68px !important;
            // }
        }