/*  
------------------------------------------------------------------------------------------------ 
    * MAJOR VENDOR PREFIXES
------------------------------------------------------------------------------------------------ 
*/

        @mixin vendorPrefix_1($attribute, $value) {
            @each $prefix_1 in -webkit-, -o-, -moz-, -ms-, '' {
                #{$prefix_1}#{$attribute}: $value;
            }
        }

        @mixin vendorPrefix_2($attribute, $value1, $value2) {
            @each $prefix_2 in -webkit-, -o-, -moz-, -ms-, '' {
                #{$prefix_2}#{$attribute}: $value1, $value2;
            }
        }



        /*  MIXIN FOR VERTICAL & HORIZONTAL ALIGNMENT
        ----------------------------------------------------------------------------- */

        @mixin autoalign {
            margin: {
                left: auto;
                right: auto;
                top: auto;
                bottom: auto;                
            }
        }