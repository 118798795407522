/*  
------------------------------------------------------------------------------------------------ 
    *footer - FOOTER STYLES ALL IN HERE
------------------------------------------------------------------------------------------------ 
*/

        /* IMPORT PARTIALS
        ------------------------------------------------------------------------- */

            @import '_scss/variables';
            @import '_scss/mixins';


        /* FOOTER
        ------------------------------------------------------------------------- */

                footer {
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    height: auto;
                    font-weight: 400;

                    a {
                        text-decoration: none;
                        outline: none;

                        &:active, &:visited, &:focus {
                            text-decoration: none;
                            outline: none;
                        }

                        &:hover {
                            text-decoration: none;
                        }

                    }

                    .footer-item {
                        font-size: 16px;
                        padding: 5px 0px;
                    }


                    h4 {
                        // font-weight: 400;
                        font-size: 16px;
                        text-transform: uppercase; 
                        padding: 10px 0px;
                    }

                    .copyright {
                        font-size: 13px;
                    }


                    .footer-img img {
                        padding-bottom: 20px;
                        max-width: 100%;
                        height: auto;
                    }
                }






        /* BACK TO TOP-BUTTON
        ------------------------------------------------------------------------- */

                .back_to_top {
                    position: fixed;
                    opacity: 0;
                    visibility: hidden;
                    overflow: hidden;
                    text-align: center;
                    z-index: 99999999;
                    background-color: $client-bg;
                    color: $bg-white;
                    width: 40px;
                    height: 40px;
                    right: 40px;
                    bottom: 40px;
                    padding-top: 1px;
                    @include vendorPrefix_1(transition, all 0.5s ease-in-out);

                    &:hover {
                        background-color: $bg-white;
                        border: 1px solid $client-bg;
                        color: $client-bg;
                        opacity: 0.9;
                    }

                    i {
                        font-size: 1.8rem;
                        color: $bg-light;
                        @include vendorPrefix_1(transition, all 0.5s ease-in-out);
                    }
                }


                .show {
                    visibility: visible;
                    cursor: pointer;
                    opacity: 1.0;
                }