/*  
------------------------------------------------------------------------------------------------ 
    *break - BREAKPOINT STYLES ALL IN HERE
------------------------------------------------------------------------------------------------ 
*/


        /* BREAKPOINTS
        ------------------------------------------------------------------------- */

                /*Large devices (desktops, less than 1200px) */
                @media (max-width: 1199.98px) {}

                /* Medium devices (tablets, less than 992px) */
                @media (max-width: 991.98px) { 

                    .team-widget-header {
                        padding: 30px 15px 50px;
                        height: 200px;
                    }

                    .team-widget-content {
                        h3, h4 {
                            font-size: 24px;
                        }
                    }

                    .pricing-widget-content h1 {
                        font-size: 32px;
                    }

                    .pricing-widget-body .textList li {
                        padding-top: 10px;
                        font-size: 18px;
                    }

                    .pricing-detail-btn {
                        width: 80%;
                        height: 40px;
                        margin-right: 3px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }

                    .text-in-pic span {
                        font-size: 16px;
                    }

                    .text-in-pic h4 {
                        font-size: 18px;
                    }
                    
                    .grid-galery .column {
                        flex: 50%;
                        max-width: 50%;
                    }

                }


                /* Small devices (landscape phones, less than 768px) */
                @media (max-width: 767.98px) {
                    
                    body {
                        padding-top: 0px;
                    }

                    .container {
                        padding: 0 !important;
                    }
                    
                    .header_img_home, .header_img_about, .header_img_portfolio, 
                    .header_img_reference, .header_img_pricing, .header_img_faq,
                    .header_img_galery, .header_img_project, .header_img_empty,
                    .header_img_location, .header_img_article, .header_img_newsletter {
                        height: 250px;
                    }

                    h1, .blogpost-header { 
                        font-size: 32px;
                        line-height: 36px; 
                    }


                    h2, .blogpost-content-short { 
                        font-size: 26px; 
                        line-height: 30px;
                    }


                    .divider {
                        margin-top: 15px;
                        margin-bottom: 15px;
                    }


                    .textBox.full, .textBox.left, .textBox.right {
                        font-size: 18px;
                        line-height: 22px;
                        text-align: left;
                    }


                    .textBoxImg img {
                        max-width: 80%;
                        height: auto;
                    }

                    // .submit-btn {
                    //     max-width: 60%;
                    // }


                    .textBoxImgText {
                        font-size: 18px;
                        line-height: 22px;
                    }



                    .textList li {
                        font-size: 18px;
                        line-height: 22px;
                    }


                    .header-parallax, #map_canvas {
                        height: 200px; 
                    }
                    

                    #contact-form input[type=text], #subscribe-form input[type=text], #covid-form input[type=text], 
                    #covid-form textarea, #contact-form textarea, #contact-form label, #subscribe-form label, 
                    #covid-form label, #mail-message, #subscribe-message {
                        font-size: 16px;
                    }


                    .accordion-custom .card-heading {
                        font-size: 18px;
                        // padding: 10px 0px 10px 50px;
                    }

                    .card-heading.lawyer {
                        font-size: 16px;
                    }


                    .card-body {
                        font-size: 18px;
                        line-height: 24px;
                    }


                    .iso-pic-label {
                        padding: 3px 3px;
                        font-size: 12px;
                    }


                    .iso-filtering ul li.nav-item.button {
                        margin-top: 4px;
                    }

                    .team-widget-body {
                        padding: 70px 15px 15px;
                    }


                    .service-widget-body {
                        padding: 70px 15px 15px;
                    }

                    .service-widget-content {
                        padding: 30px 0px 0px 0px;
                    }


                    .portfolio-widget-content h3 {
                        padding: 10px 0px 0px 0px;
                    }

                    .portfolio-widget-content .textBox {
                        padding: 20px 0px 0px 0px;
                    }

                    .reference-widget-content h3, .reference-widget-content h4 {
                        font-size: 20px;
                    }

                    .blogpost-overview-content {
                        height: 0px;
                        margin-bottom: 0px;
                        visibility: hidden;
                    }

                    .mb-30 {
                        margin-bottom: 15px;
                    }

                
                    footer {
                        .sitemap, .opening, .address {
                            padding-left: 10px;
                            padding-right: 10px;
                            padding-bottom: 40px;
                            text-align: center !important;
                        }

                        h4 {
                            text-align: center !important;
                        }
                        
                        .footer-img img {
                            padding: 15px;
                            max-width: 45%;
                            height: auto;
                        }
                    }

                    .text-in-pic img {
                        height: 80px !important;
                    }
                    

                    .back_to_top {
                        right: 20px;
                    }


                    .news-headline {
                        font-size: 22px;
                        line-height: 28px;
                    }

                }


                /* Extra small devices (portrait phones, less than 576px) */
                @media (max-width: 575.98px) {
                    
                    
                    h1, .blogpost-header { 
                        font-size: 28px; 
                        line-height: 34px;
                    }

                    h2, .blogpost-content-short { 
                        font-size: 22px; 
                        line-height: 26px;
                    }

                    h3 {
                        font-size: 22px;
                        font-weight: 400;
                    }
                    
                    .service-widget-header {
                        padding: 30px 15px 50px;
                        height: 90px;
                    }

                    .service-widget-image {
                        padding: 8px;
                        width: 120px;
                        height: 120px;
                        top: -60px;
                        left: 50%;
                        margin-left: -60px;
                        border-radius: 120px;
                    }


                    .service-widget-image i {
                        padding: 35px 32px 35px 35px;
                        font-size: 2rem;
                    }


                    .service-widget-content {
                        padding: 30px 0px 0px 0px;
                    }

                    .service-widget-content h3, .service-widget-content h4 {
                        font-size: 24px;
                    }


                    .portfolio-widget-image {
                        padding: 8px;
                        width: 80px;
                        height: 80px;
                        top: -40px;
                        left: 50%;
                        margin-left: -40px;
                        border-radius: 80px;
                    }


                    .portfolio-widget-image i {
                        padding: 15px 15px 20px 15px;
                        font-size: 2rem;
                    }


                    .portfolio-widget-content {
                        padding: 15px 0px 0px 0px;
                    }


                    .portfolio-widget-content h3, .service-widget-content h4 {
                        font-size: 24px;
                    }

                    .iso-pic.brands {
                        background-size: 90%;
                    }


                    .iso-pic.brands img {
                        height: 80px;
                    }


                    .iso-pic.glasses {
                        background-size: 100%;
                    }


                    .iso-pic.glasses img {
                        height: 150px;
                    }

                    .text-in-pic span h4 {
                        font-size: 18px;
                    }

                    .text-in-pic img {
                        height: 60px !important;
                    }


                    .pic-slide {
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 70% auto;
                        background-size: contain;
                    }

                    .arrow-left, .arrow-right, .swiper-pagination {
                        display: none;
                    }


                    .text-in-pic span {
                        font-size: 16px;
                        padding: 20px;
                        line-height: 24px;
                    }


                    ul, ol {
                        padding-left: 5px;
                    }


                    .grid-galery {
                        padding: 0 !important;
                    }


                    .grid-galery .column {
                        flex: 100%;
                        max-width: 100%;
                        padding: 0;

                        img {
                            margin-top: 15px;
                        }
                    }

                    footer .footer-img img {
                        padding-bottom: 20px;
                        max-width: 50%;
                        height: auto;
                    }


                    .news-headline {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    .header-parallax, #map_canvas {
                        height: 150px; 
                    }
                }


                
