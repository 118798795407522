/*  
------------------------------------------------------------------------------------------------ 
    * MAJOR COLOR VARIABLES
------------------------------------------------------------------------------------------------ 
*/

        // LIGHT THEME
        $bg-dark: #eddfca;
        $bg-gray: #0e0e0e;
        $bg-black: #aea597;
        $bg-light: #aea597;
        // $bg-light: #a68a73;
        $bg-white: #ccc9c5;
        $bg-footer: #7b7366;
        $bg-search: #ebebeb;

        $text-dark: #e9d2af;
        $text-gray: $bg-gray;
        $text-black: $bg-black;
        $text-light: #ccc9c5;
        $text-white: $text-gray;
        $text-footer: $text-light;
        $text-search: $bg-white;

        $border-dark: $bg-dark;
        $border-gray: $bg-gray;
        $border-black: $bg-black;
        $border-light: #ccc9c5;
        $border-white: $bg-white;
        $border-footer: rgba(233, 210, 175, 0.25);
        $border-search: $bg-white;
    
        $client-bg:  #543200;
        $client-bg-hover: #e9d2af;
        $client-svg: #3a3328;
        $client-text: #c2c2c2;

        $form-text-invalid: #6b151a;
        
        // LIGHT NAVIGATION THEME
        $nav-top-header-bg:#aea597;
        // $nav-top-header-bg:#ccc9c5;
        $nav-top-header-text:#e9d2af;
        $nav-bg: #ccc9c5;
        // $nav-bg: #aea597;
        $nav-text: #0e0e0e;
        $nav-border: #e9d2af;
        $nav-svg: #0e0e0e;
        $nav-active-bg: #aea597;
        $nav-active-text: #0e0e0e;
        $nav-hover-bg: #aea597;
        $nav-hover-text: #0e0e0e;
        $nav-hover-svg-bg: #aea597;
        $nav-hover-svg-text: #0e0e0e;
        $nav-dropdown-bg: #ccc9c5;
        $nav-dropdown-text: $nav-top-header-text;