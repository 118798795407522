/*  
------------------------------------------------------------------------------------------------ 
    *body - SITE STYLES ALL IN HERE
------------------------------------------------------------------------------------------------ 
*/
/* IMPORT PARTIALS
        ------------------------------------------------------------------------- */
/*  
------------------------------------------------------------------------------------------------ 
    * MAJOR COLOR VARIABLES
------------------------------------------------------------------------------------------------ 
*/
/*  
------------------------------------------------------------------------------------------------ 
    * MAJOR VENDOR PREFIXES
------------------------------------------------------------------------------------------------ 
*/
/*  MIXIN FOR VERTICAL & HORIZONTAL ALIGNMENT
        ----------------------------------------------------------------------------- */
/* LOAD FONTS
        ------------------------------------------------------------------------- */
/* ROBOTO */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local(""), url("../fonts/roboto-v30-latin-100.woff2") format("woff2"), url("../fonts/roboto-v30-latin-100.woff") format("woff"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/roboto-v30-latin-300.woff2") format("woff2"), url("../fonts/roboto-v30-latin-300.woff") format("woff"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/roboto-v30-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v30-latin-regular.woff") format("woff"); }

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(""), url("../fonts/roboto-v30-latin-italic.woff2") format("woff2"), url("../fonts/roboto-v30-latin-italic.woff") format("woff"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/roboto-v30-latin-500.woff2") format("woff2"), url("../fonts/roboto-v30-latin-500.woff") format("woff"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/roboto-v30-latin-700.woff2") format("woff2"), url("../fonts/roboto-v30-latin-700.woff") format("woff"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/roboto-v30-latin-900.woff2") format("woff2"), url("../fonts/roboto-v30-latin-900.woff") format("woff"); }

/* DEFAULT VALUES
        ------------------------------------------------------------------------- */
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

html, body {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  word-wrap: break-word; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 300; }

h1 {
  font-size: 48px; }

h2 {
  font-size: 38px; }

h3 {
  font-size: 32px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

section a, button a, .modal-content a, .modal-content.show-geo-error a {
  text-decoration: none !important;
  outline: none !important; }

section p, button p, .modal-content p, .modal-content.show-geo-error p {
  margin-bottom: 0 !important; }

.modal-content, .modal-content.show-geo-error {
  border-radius: 0% !important; }

button:focus {
  outline: 0; }

/* LOADER
        ------------------------------------------------------------------------- */
.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999; }
  .loader .bar-container {
    height: 2px;
    background: #aea597;
    width: 100px;
    margin-left: -50px;
    top: 50%;
    left: 50%;
    position: absolute; }
    .loader .bar-container .bar {
      position: relative;
      left: 0;
      top: 0;
      width: 0;
      height: 2px; }

/* WELCOME AREA ( fullsize-img & text )
        ------------------------------------------------------------------------- */
.header_img_home, .header_img_about, .header_img_portfolio,
.header_img_reference, .header_img_pricing, .header_img_faq,
.header_img_galery, .header_img_project, .header_img_empty,
.header_img_location, .header_img_article, .header_img_newsletter {
  max-width: 100%;
  width: inherit;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.header-parallax, #map_canvas {
  max-width: 100%;
  width: inherit;
  height: 300px; }

.header_img_home {
  background-image: url(../img/pics/img-header-home.jpg); }

.header_img_about {
  background-image: url(../img/pics/img-header-about.jpg); }

.header_img_portfolio {
  background-image: url(../img/pics/img-header-portfolio.jpg); }

.header_img_reference {
  background-image: url(../img/pics/img-header-reference.jpg); }

.header_img_pricing {
  background-image: url(../img/pics/img-header-pricing.jpg); }

.header_img_galery {
  background-image: url(../img/pics/img-header-galery.jpg); }

.header_img_project {
  background-image: url(../img/pics/img-header-project.jpg); }

.header_img_faq {
  background-image: url(../img/pics/img-header-faq.jpg); }

.header_img_empty {
  background-image: url(../img/pics/img-header-empty.jpg); }

.header_img_location {
  background-image: url(../img/pics/img-header-location.jpg); }

.header_img_article {
  background-image: url(../img/pics/img-header-article.jpg); }

.header_img_newsletter {
  background-image: url(../img/pics/img-header-newsletter.jpg); }

.custom-control-label::before, .custom-control-label::after {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-color: #ccc9c5 !important;
  border-radius: none; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0rem; }

/* CONTENT AREA
        ------------------------------------------------------------------------- */
.divider {
  height: 1px;
  border-bottom: 1px solid; }

.textBox.full, .textBox.left, .textBox.right {
  font-size: 18px;
  line-height: 24px;
  text-align: justify; }

.textBox.full.lawyer, .textBox.left.lawyer, .textBox.right.lawyer {
  font-size: 16px;
  line-height: 20px;
  text-align: left; }

.textList.lawyer li {
  margin-left: 15px;
  padding: 6px;
  font-size: 16px;
  line-height: 20px;
  text-align: left; }

.textBoxImg {
  text-align: center;
  margin-bottom: 15px; }

.textBoxImg img {
  max-width: 80%;
  height: auto; }

.textBoxImg.lawyer img {
  max-width: 80%;
  height: auto; }

.textBoxImgText {
  font-size: 18px;
  line-height: 24px;
  text-align: center; }

.textList {
  margin-bottom: 20px;
  list-style: square; }

.textList li {
  margin-left: 15px;
  padding: 6px;
  font-size: 18px;
  line-height: 24px; }

.text-in-pic {
  position: relative; }

.text-in-pic img {
  display: block;
  opacity: 0.7; }

.text-in-pic span {
  position: absolute;
  padding: 30px;
  line-height: 28px; }

.text-in-pic-01 {
  background-image: url(../img/pics/01.jpg); }

.text-in-pic-02 {
  background-image: url(../img/pics/einsehbar01.jpg); }

.text-in-pic-03 {
  background-image: url(../img/pics/01.jpg); }

.text-in-pic-04 {
  background-image: url(../img/pics/einsehbar02.jpg); }

.text-in-pic-05 {
  background-image: url(../img/pics/01.jpg); }

.text-in-pic-06 {
  background-image: url(../img/pics/einsehbar03.jpg); }

.text-in-pic-07 {
  background-image: url(../img/pics/01.jpg); }

.text-in-pic-08 {
  background-image: url(../img/pics/einsehbar04.jpg); }

.text-in-pic-09 {
  background-image: url(../img/pics/01.jpg); }

.text-in-pic-10 {
  background-image: url(../img/pics/02.jpg); }

.text-in-pic-11 {
  background-image: url(../img/pics/MiYOSMART-1080x1080-01.jpg); }

.text-in-pic-12 {
  background-image: url(../img/pics/MiYOSMART-1080x1080-02.jpg); }

.location-pic-01 {
  background-image: url(../img/galery-02/image1.jpg); }

.location-pic-02 {
  background-image: url(../img/galery-02/image2.jpg); }

.location-pic-03 {
  background-image: url(../img/galery-02/8L3A7552.jpg); }

.location-pic-04 {
  background-image: url(../img/galery-02/1W4A0919.jpg); }

#map-canvas-1, #map-canvas-2 {
  width: 100%;
  height: 300px; }

.location-pic-02, .location-pic-04 {
  height: 300px; }

#extra-space {
  margin-bottom: 500px; }

/* FLEX
        ------------------------------------------------------------------------- */
.row-flex, .row-flex > div[class*='col-'] {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1 1 auto; }

.row-flex-wrap {
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  align-content: flex-start;
  flex: 0; }

.row-flex > div[class*='col-'], .container-flex > div[class*='col-'] {
  margin: -.2px;
  /* hack adjust for wrapping */ }

.container-flex > div[class*='col-'] div, .row-flex > div[class*='col-'] div {
  width: 100%; }

.flex-col {
  display: flex;
  display: -webkit-flex;
  flex: 1 100%;
  flex-flow: column nowrap; }

.flex-grow {
  display: flex;
  -webkit-flex: 2;
  flex: 2; }

/* FAQ - ACCORDION
        ------------------------------------------------------------------------- */
.accordion-custom {
  margin-top: 20px; }

.card {
  margin-bottom: 15px;
  border-radius: 0;
  border: 1px solid; }

.card-header {
  border: none; }

.card-header a span {
  margin-left: 10px; }

.accordion-custom .card-heading {
  font-size: 20px;
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  position: relative; }

.card-heading.lawyer {
  font-size: 16px;
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 8px;
  position: relative; }

.card-body {
  font-size: 18px;
  line-height: 24px;
  text-align: justify; }

.card-body.lawyer {
  padding: 15px 30px 15px 30px; }

/* BLOGPOST
        ------------------------------------------------------------------------- */
.blogpost-divider-1 {
  margin-top: 30px;
  margin-bottom: 17px; }

.blogpost-divider-2 {
  margin-top: 17px;
  margin-bottom: 30px; }

/* header */
.blogpost-overview-header {
  font-size: 20px;
  line-height: 28px;
  margin: 15px 0px 15px 0px; }

.blogpost-header h1 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin: 15px 0px 15px 0px; }

.blogpost-overview-author, .blogpost-author {
  font-size: 16px;
  margin: 0px 0px 15px 0px; }

.blogpost-author {
  font-weight: 500; }

.blogpost-overview-image, .blogpost-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0px; }

.blogpost-overview-image img, .blogpost-image img {
  max-width: 100%;
  height: auto; }

.blogpost-overview-image-copyright, .blogpost-image-copyright {
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0px 5px 0px; }

.blogpost-overview-image img + span.badge {
  position: relative;
  top: -190px;
  left: 4px; }

.blogpost-overview-content-short {
  font-size: 18px;
  line-height: 26px;
  margin: 15px 0px 40px 0px; }

.blogpost-content-short h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  margin: 20px 0px 40px 0px; }

.blogpost-content {
  margin: 40px 0px 100px 0px; }

.blogpost-content ol, .blogpost-content ul {
  margin-bottom: 20px !important;
  list-style: square !important; }

.blogpost-content ol li, .blogpost-content ul li {
  margin-left: 15px !important;
  padding: 6px !important;
  font-size: 18px;
  line-height: 24px; }

#blogpost-overview-search-form input, #blogpost-navigate-form-1 input, #blogpost-navigate-form-2 input {
  padding-top: 10px;
  padding-bottom: 10px;
  text-indent: 15px;
  font-size: 17px;
  font-weight: normal;
  border: none;
  outline: none; }

#blogpost-overview-search-form button, #blogpost-navigate-form-1 button, #blogpost-navigate-form-2 button {
  border: none;
  opacity: 1;
  padding: 0px 12px 0px 12px;
  cursor: pointer; }

#blogpost-overview-space-to-bottom {
  margin-bottom: 500px; }

/* ABOUT US - TEAM-WIDGET
        ------------------------------------------------------------------------- */
.team-widget-header {
  position: relative;
  padding: 30px 15px 50px;
  height: 200px;
  overflow: hidden; }

.team-widget-content h3, .team-widget-content h4 {
  font-weight: 300; }

.team-widget-body {
  height: auto;
  position: relative;
  padding: 90px 15px 15px; }

.team-widget-image {
  position: absolute;
  display: inline-block;
  padding: 8px;
  width: 140px;
  height: 140px;
  top: -70px;
  left: 50%;
  margin-left: -70px;
  border-radius: 120px; }

.team-pic-01 {
  background-image: url(../img/team/Ramona-Schaefer.jpg); }

.team-pic-02 {
  background-image: url(../img/team/Claudia-Schneider.jpg); }

.team-pic-03 {
  background-image: url(../img/team/Rebecca-Stehle.jpg); }

.team-pic-04 {
  background-image: url(../img/galery-02/8L3A7552.jpg); }

.team-pic-05 {
  background-image: url(../img/galery-02/1W4A0137.jpg); }

.team-pic-06 {
  background-image: url(../img/galery-02/1W4A0919.jpg); }

/* ABOUT US - SERVICE-WIDGET
        ------------------------------------------------------------------------- */
.service-widget-header {
  position: relative;
  padding: 30px 15px 50px;
  height: 50px;
  overflow: hidden; }

.service-widget-content {
  padding: 20px 0px 20px 0px; }
  .service-widget-content h3, .service-widget-content h4 {
    font-weight: 300; }

.service-widget-body {
  height: auto;
  position: relative;
  padding: 90px 15px 15px; }

.service-widget-image-round,
.service-widget-block,
.service-widget-image-transparent {
  position: absolute;
  display: inline-block;
  padding: 8px;
  width: 140px;
  height: 140px;
  top: -70px;
  left: 50%;
  margin-left: -70px;
  border-radius: 120px;
  text-align: center;
  vertical-align: middle; }

.service-widget-block {
  border-radius: 0; }

.service-widget-image-round svg.svg-inline--fa,
.service-widget-block svg.svg-inline--fa,
.service-widget-image-transparent svg.svg-inline--fa {
  font-size: 48px;
  margin-top: 34px; }

/* PORTFOLIO - PORTFOLIO-WIDGET
        ------------------------------------------------------------------------- */
.portfolio-widget-header {
  position: relative;
  padding: 15px 15px 20px;
  height: 0px;
  overflow: hidden;
  white-space: nowrap; }

.portfolio-widget-content {
  padding: 20px 0px 20px 0px; }
  .portfolio-widget-content h3 {
    padding-bottom: 20px;
    font-weight: 300; }
  .portfolio-widget-content h4 {
    font-weight: 300; }

.portfolio-widget-body {
  height: auto;
  position: relative;
  padding: 30px 15px 15px; }

.portfolio-widget-image {
  position: absolute;
  display: inline-block;
  padding: 8px;
  width: 140px;
  height: 140px;
  top: -70px;
  left: 50%;
  margin-left: -70px;
  border-radius: 120px;
  text-align: center;
  vertical-align: middle; }

.portfolio-widget-image svg.svg-inline--fa {
  color: #3a3328;
  font-size: 48px;
  margin-top: 34px; }

/* PRICING-WIDGET
        ------------------------------------------------------------------------- */
.pricing-widget-header {
  position: relative;
  padding: 30px 15px 50px;
  height: 160px;
  overflow: hidden; }

.pricing-widget-content h1 {
  font-weight: 300; }
  .pricing-widget-content h1 small {
    font-size: 60%; }

.pricing-widget-body {
  height: auto;
  position: relative;
  padding: 20px; }
  .pricing-widget-body .textList {
    list-style: none !important;
    margin: 0px;
    padding: 0px; }
    .pricing-widget-body .textList li {
      padding-top: 15px;
      font-size: 18px; }

.pricing-detail-btn {
  width: 100%;
  height: 55px;
  margin-right: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: normal;
  opacity: 0.8;
  border: none;
  cursor: pointer; }

.card-header.pricing {
  padding: 0px;
  border-bottom: 1px solid inherit; }

/* ISOTOPE CONTAINER - used for GALERY, ABOUT US, TAMREFERENCES & PROJECTS 
        /* OVERVIEW-SITE - INCLUDING FILTERING
        ------------------------------------------------------------------------- */
.iso-filtering {
  position: relative; }
  .iso-filtering ul li.nav-item.button {
    margin-top: 15px; }

.iso-grid {
  margin-left: 0px; }

.iso-pic-container {
  margin: 0px;
  padding: 0px; }

.iso-pic {
  margin-right: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .iso-pic img {
    width: 100%; }
  .iso-pic .blogpost {
    margin-right: 30px; }
  .iso-pic .brands {
    background-size: 80%; }
    .iso-pic .brands img {
      height: 200px; }
  .iso-pic .glasses {
    background-size: 95%; }
    .iso-pic .glasses img {
      height: 200px; }

.brands {
  background-size: 80%; }

.incl-label {
  margin-bottom: 0px; }

.no-label {
  margin-bottom: 30px; }

.iso-pic-label {
  overflow: hidden;
  white-space: nowrap;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
  padding: 5px 5px;
  font-size: 14px; }

.iso-pic.project-pic-01 {
  background-image: url(../img/project-01/project-01.jpg); }

.iso-pic.project-pic-02 {
  background-image: url(../img/project-01/project-02.jpg); }

.iso-pic.project-pic-03 {
  background-image: url(../img/project-01/project-03.jpg); }

.iso-pic.project-pic-04 {
  background-image: url(../img/project-01/project-04.jpg); }

.iso-pic.project-pic-05 {
  background-image: url(../img/project-01/project-05.jpg); }

.iso-pic.project-pic-06 {
  background-image: url(../img/project-01/project-06.jpg); }

.iso-pic.project-pic-07 {
  background-image: url(../img/project-02/project-01.jpg); }

.iso-pic.project-pic-08 {
  background-image: url(../img/project-02/project-02.jpg); }

.iso-pic.project-pic-09 {
  background-image: url(../img/project-02/project-03.jpg); }

.iso-pic.project-pic-10 {
  background-image: url(../img/project-02/project-04.jpg); }

.iso-pic.project-pic-11 {
  background-image: url(../img/project-02/project-05.jpg); }

.iso-pic.project-pic-12 {
  background-image: url(../img/project-02/project-06.jpg); }

/* GRID-GALERY
        ------------------------------------------------------------------------- */
.grid-galery {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 8px; }
  .grid-galery .column {
    flex: 25%;
    max-width: 25%;
    padding: 0px 8px; }
    .grid-galery .column img {
      margin-top: 16px;
      vertical-align: middle;
      width: 100%; }

/* GOOGLE MAPS
        ------------------------------------------------------------------------- */
/* CONTACT FORMULAR
        ------------------------------------------------------------------------- */
#contact-form input[type=text], #subscribe-form input[type=text],
#covid-form input[type=text], #covid-form input[type=select],
#storno-form input[type=text], #storno-form input[type=select],
#order-form input[type=text] {
  display: block;
  width: 100%;
  margin-right: 3px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-indent: 15px;
  font-size: 18px;
  font-weight: normal;
  border: none;
  outline: none; }

#contact-form textarea, #covid-form textarea, #storno-form textarea, #order-form textarea {
  display: block;
  width: 100%;
  margin-right: 3px;
  padding-top: 15px;
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  text-indent: 15px;
  overflow: auto;
  border: none;
  outline: none; }

#contact-form label, #subscribe-form label, #covid-form label, #storno-form label, #order-form label {
  display: block;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.03em;
  margin-top: 15px; }

#contact-form small, #subscribe-form small, #covid-form small, #storno-form small, #order-form small {
  font-size: 100%;
  font-weight: inherit; }

#mail-message, #subscribe-message {
  padding: 0px 0px 20px 0px;
  width: 100%;
  height: auto;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  font-weight: normal; }

.form-row {
  margin-left: 0px;
  margin-right: 0px; }

.form-check {
  padding: 0px;
  margin-bottom: 10px; }

.form-check-label {
  margin-left: 10px; }

.form-check-input {
  margin-top: 20px; }

.form-check-inline .form-check-input {
  margin-top: 13px; }

.custom-control-label {
  padding-left: 10px; }

.submit-btn {
  width: 100%;
  font-weight: normal;
  opacity: 0.6;
  border: none;
  cursor: pointer; }

.is-invalid {
  font-size: 14px;
  font-style: italic;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%; }

#nl-button {
  padding: 15px 0px;
  font-size: 24px;
  font-weight: 500;
  width: 350px;
  height: auto;
  cursor: pointer; }

/* COOKIE-WINDOW
        ------------------------------------------------------------------------- */
.modal {
  z-index: 99999 !important; }

.cookie-window {
  position: relative;
  opacity: 1;
  overflow: hidden;
  width: auto;
  height: auto;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

/* SWIPER STYLES
        ------------------------------------------------------------------------- */
html {
  height: 100%; }

body.slider {
  margin: 0;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  position: relative;
  height: 100%;
  background-color: #aea597; }

.galery-loader {
  background-color: #aea597;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1200; }
  .galery-loader svg {
    color: #543200;
    top: 50%;
    left: 50%;
    margin-top: -18px;
    margin-left: -18px;
    position: absolute; }

.swiper-container {
  width: 92%;
  height: 92%;
  color: #0e0e0e;
  text-align: center;
  padding-top: 6%;
  background: transparent !important; }

.pic-slide {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80% auto;
  background-size: contain; }

.arrow-left {
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 1300; }

.arrow-right {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1300; }

.swiper-pagination {
  position: inherit;
  text-align: center;
  vertical-align: middle;
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px; }

.swiper-pagination-bullet {
  margin-right: 10px;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  opacity: .3; }

.swiper-pagination-bullet-active {
  opacity: 1; }

.close-btn {
  position: absolute;
  text-decoration: none;
  font-weight: 500;
  right: 1%;
  top: 2%;
  z-index: 1300;
  color: #aea597;
  cursor: pointer; }
  .close-btn svg {
    color: #ccc9c5; }
    .close-btn svg:hover {
      color: #543200; }

/* SPECIAL CSS 4 PATIENTS
        ------------------------------------------------------------------------- */
.textBox svg {
  margin-top: 6px;
  font-size: 38px !important; }
  .textBox svg.mail {
    margin-top: 1px;
    font-size: 28px !important; }

.modal-header.allow-cookie, .modal-header.remove-question,
.modal-header.show-statistic, .modal-header.show-geo-error {
  padding: 0px !important;
  border: none !important; }
  .modal-header.allow-cookie img, .modal-header.remove-question img,
  .modal-header.show-statistic img, .modal-header.show-geo-error img {
    width: 100% !important; }
  .modal-header.allow-cookie h4, .modal-header.remove-question h4,
  .modal-header.show-statistic h4, .modal-header.show-geo-error h4 {
    margin-left: 30px !important; }

.modal-body.allow-cookie, .modal-body.remove-question,
.modal-body.show-statistic, .modal-body.show-geo-error {
  padding: 20px 40px 0px 40px !important;
  font-size: 18px; }

button.close {
  position: absolute;
  top: 0px;
  right: 15px;
  font-weight: 500;
  line-height: 1;
  color: #3a3328;
  opacity: 1; }

.modal-footer.allow-cookie, .modal-footer.remove-question,
.modal-footer.show-statistic, .modal-footer.show-geo-error {
  border: none; }
  .modal-footer.allow-cookie button, .modal-footer.remove-question button,
  .modal-footer.show-statistic button, .modal-footer.show-geo-error button {
    font-size: 18px !important;
    padding: 4px 20px !important; }

.news-headline {
  font-size: 28px;
  line-height: 34px; }
